<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import DrawerForm from '../../../components/DrawerForm'
import moment from 'moment'
import { regSourceList } from '@/utils/textFile'
import apiTool from '@/command/apiTool'
import { getAction } from '@/command/netTool'

export default {
  name: 'userManagement',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmUser/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          name: '手机号',
          key: 'telephone',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.headImg ? <img src={records.headImg.split(',')[0]} /> : ''}
                <div>{text}</div>
              </div>
            )
          },
        },
        {
          dataIndex: 'telephone',
          title: '手机号',
          align: 'left',
        },
        {
          dataIndex: 'lastBuyTime',
          title: '最近消费时间',
          align: 'left',
          onExport: (text, records) => {
            return text ? moment(text).format('YYYY.MM.DD HH:mm') : ''
          },
          customRender: function (text, records) {
            return text ? moment(text).format('YYYY.MM.DD HH:mm') : ''
          },
          sorter: (a, b) => moment(a.lastBuyTime).unix() - moment(b.lastBuyTime).unix(),
        },
        {
          dataIndex: 'totalBuyAmount',
          title: '累积消费',
          align: 'left',
          sorter: (a, b) => a.totalBuyAmount - b.totalBuyAmount,
        },
        {
          dataIndex: 'level',
          title: '会员等级',
          align: 'left',
          customRender: function (text, records) {
            return 'Lv' + text
          },
        },
        {
          dataIndex: 'integral',
          title: '积分',
          align: 'left',
        },
        {
          dataIndex: 'regSource',
          title: '来源渠道',
          filters: regSourceList,
          customRender: (text, record) => {
            return regSourceList[text].text
          },
          filterMultiple: false,
        },
        {
          dataIndex: 'status',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '冻结',
              value: '2',
            },
            {
              text: '注销',
              value: '1',
            },
            {
              text: '正常',
              value: '0',
            },
          ],
          onExport: (records) => {
            return ['正常', '注销', '冻结'][records]
          },
          render(data) {
            return {
              showDot: true,
              name: ['正常', '注销', '冻结'][data],
              color: ['green', 'grey', 'red'][data],
            }
          },
          filterMultiple: false,
        },
        {
          dataIndex: 'winPrizeCount',
          title: '备注',
          align: 'left',
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                display: records.status !== '1',
                name: records.status == '0' ? '冻结' : '恢复',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/farmUser/${records.status == 0 ? 'disable' : 'enable'}?userId=${records.id}`,
                    params: {
                      userId: records.id,
                    },
                  })
                },
              },
              {
                display: true,
                name: '加标签',
                onClick: () => {
                  getAction('/farmUser/queryTagList?userId=' + records.id, {}, '/api').then((r) => {
                    if (r.code == 200) {
                      this.handleLabelsPopup({
                        ...records,
                        labelIds: r.data
                          .filter((e) => e.light == '1')
                          .map((e) => ({
                            name: e.labelName,
                            value: e.id,
                          })),
                        labelList: r.data.map((e) => ({
                          name: e.labelName,
                          value: e.id,
                        })),
                      })
                    }
                  })
                },
              },
              {
                display: true,
                name: '详情',
                onClick: () => this.$router.push(`/platform/userManagementDetail?id=${records.id}`),
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getDrawerForm(records) {
      const form = [
        {
          title: '',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '会员卡号',
                  value: records.id || '未知',
                },
                {
                  name: '手机号',
                  value: records.telephone || '未知',
                },
                {
                  name: '会员姓名',
                  value: records.name || '未知',
                },
                {
                  name: '会员性别',
                  value: '未知',
                },
              ],
            },
          ],
        },
        {
          form: [
            {
              type: 'labels',
              key: 'labelIds',
              typeData: records.labelList,
              title: '用户标签',
            },
          ],
        },
      ]
      return form
    },
    handleLabelsPopup(dataSource = {}) {
      let that = this
      apiTool.showDrawer({
        title: '添加标签',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(dataSource, formData),
          form: dataSource,
          //   formProps: {
          //     labelCol: { span: 3 },
          //     wrapperCol: { span: 20 }
          //   }
        },
        success: ({ data, setHidden }) => {
          api.command.create
            .call(that, {
              url: '/farmUser/saveTag',
              params: { labelIds: data.labelIds.map((e) => e.value), userId: data.id },
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return []
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
